import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// Images
import LoginBack from '../../assets/image/image4.png'
import FormBack from '../../assets/image/Group.png'
import LogoImg from '../../assets/image/logo.png'




const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',

  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 5),
  
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login"
      sx={{
        backgroundImage: `url(${LoginBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
      <RootStyle>
     
        {/* <HeaderStyle>
      <Image src={LogoImg} sx={{ width: 100, height: 40, }}/>
        
          <Logo />
          <Image
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle> */}

        {/* {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              alt="login"
              src="https://minimal-assets-api.vercel.app/assets/illustrations/illustration_login.png"
            />
          </SectionStyle>
        )} */}

<Container maxWidth="sm">

  <ContentStyle
    sx={{
      backgroundImage: `url(${FormBack})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '400px', // Adjust this value as needed to reduce the height
    }}
  >
    <Box sx={{ backgroundColor: "white", p: 5 }}>
      <Box sx={{display:"flex" , justifyContent:"center", }}>
      <Image src={LogoImg} sx={{ width: 100, height: 40 ,display:"flex" , justifyContent:"center", }}/>
      </Box>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1, color: 'black' }}>
          <Typography variant="h4" gutterBottom>
            Sales Person Sign In
          </Typography>
          <Typography>Enter your details below.</Typography>
        </Box>
      </Stack>

      <LoginForm />
    </Box>
  </ContentStyle>
</Container>
      </RootStyle>
    </Page>
  );
}
